import { AddressLine } from '@/settings/domain/address/AddressLine';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { ShippingMethod } from '@/settings/domain/shippingMethod/ShippingMethod';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { Transport } from '@/tracking/domain/typetransport/Transport';
import { Bulk } from '../bulk/Bulk';
import { TrackingNumber } from '../trackingNumber/TrackingNumber';
import { PreRegisterStatusEnum } from './PreRegisterStatusEnum';
import { TimelineTransactional } from '@/tracking/domain/timelineTransactional/TimelineTransactional';
import { Store } from '../layout/store/Store';
import { Containers } from '@/settings/domain/containers/Containers';
import { GeneralPort } from '@/settings/domain/generalPort/GeneralPort';
import { Currency } from '@/settings/domain/currency/Currency';
import { Branch } from '@/settings/domain/branch/Branch';
import { PreRegisterTypeEnum } from './PreRegisterTypeEnum';
import { BillOfLanding } from '../billOfLanding/BillOfLanding';

export class PreRegister {
  typeOperationData: TypeOperation | null;
  typeOperation: number;
  shippingMethod: ShippingMethod | null;
  typeTransport: Transport | null;
  warehouse: Warehouse | null;
  store: Store | null;
  number: string;
  trackingNumber: TrackingNumber | null;
  container: string;
  date: string;
  sender: Entity | null;
  recipient: Entity;
  address: AddressLine;
  addressDestination: AddressLine;
  cargoTo: Entity | null;
  transportistName: string;
  transportistId: string;
  deliveryName: Entity | null;
  licensePlate: string;
  bulks: Bulk[];
  bulksF: Bulk[];
  containers: Bulk[];
  boxes: Bulk[];
  notes: string;
  receivedBy: string;
  cubedBy: string;
  type: string;
  state: PreRegisterStatusEnum;
  estimatedArrivalDate: string;
  estimatedDepartureDate: string;
  lastNumber: string;
  lastType: string;
  timeline: TimelineTransactional | null;
  consignee: Entity | null;
  containerType: Containers | null;
  portOrigin: GeneralPort | null;
  portDestination: GeneralPort | null;
  documentType: DocumentType | null;
  //Solicitar las siguientes en el backend
  currency: Currency | null;
  branch: Branch | null;
  rateValue: number;
  routeId: number;
  customer: Entity | null;
  condition: string | { id: number; name: string };
  deliveryDateTime: string;
  dateTime: string;
  // temporales
  cargoType: 'package' | 'document' | 'none';
  subTotal: number;
  taxesValue: number;
  masterBillOfLading: BillOfLanding | null;
  preRegisterPreparation: string | null;

  public constructor(type?: string) {
    this.typeOperationData = null;
    this.typeOperation = 1;
    this.shippingMethod = null;
    this.typeTransport = null;
    this.warehouse = null;
    this.store = null;
    this.number = '0';
    this.trackingNumber = null;
    this.container = '';
    this.date = '';
    this.sender = null;
    this.recipient = new Entity();
    this.address = new AddressLine();
    this.addressDestination = new AddressLine();
    this.cargoTo = null;
    this.transportistName = '';
    this.transportistId = '';
    this.deliveryName = null;
    this.licensePlate = '';
    this.bulks = [];
    this.containers = [];
    this.boxes = [];
    this.bulksF = [];
    this.notes = '';
    this.receivedBy = '';
    this.cubedBy = '';
    this.type = type ?? '';
    this.state = PreRegisterStatusEnum.PARTIAL_PRE_REGISTER;
    this.estimatedArrivalDate = '';
    this.lastNumber = '0';
    this.lastType = '';
    this.timeline = null;
    this.consignee = type === PreRegisterTypeEnum.COURIER_ORDER ? new Entity() : null;
    this.containerType = null;
    this.portOrigin = null;
    this.portDestination = null;
    this.documentType = null;
    this.currency = null;
    this.rateValue = 0;
    this.routeId = 0;
    this.customer = new Entity();
    this.condition = 'cash';
    this.deliveryDateTime = '';
    this.dateTime = '';
    this.cargoType = 'package';
    this.estimatedDepartureDate = '';
    this.branch = null;
    this.subTotal = 0;
    this.taxesValue = 0;
    this.masterBillOfLading = null;
    this.preRegisterPreparation = null;
  }
}
