
import Vue from 'vue';
import Component from 'vue-class-component';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import ShippingDetails from '../../shippingDetails/ShippingDetails.vue';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Containers } from '@/settings/domain/containers/Containers';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { TYPES } from '@/core/config/Types';
import { FindByReferencePackaging } from '@/wms/application/catalogs/packaging/search/FindByReferencePackaging';
import { References } from '@/wms/domain/catalogs/references/References';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { preRegisterSteps } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import ConsigneeSearch from '@/core/components/shared/ConsigneeSearch.vue';
import CargoOwners from '../cargoOwners/CargoOwners.vue';
import ReferenceSearch from '@/core/components/shared/ReferenceSearch.vue';

interface Lists {
  containerList?: Containers[];
  originList?: Territories[];
  destinationList?: Territories[];
  commodityList?: CommodityType[];
  packagingList?: PackingUnits[];
  referencesList?: any[];
  typeOperationList?: TypeOperation[];
  locationList?: ViewLocation[];
  weightList?: any[];
  commodityClassesList?: CommodityClass[];
  customerList?: Entity[];
  billOfLandingList?: BillOfLanding[];
  singleReferencesList?: References[];
}

@Component({
  name: 'AddReference',
  components: { ShippingDetails, ConsigneeSearch, CargoOwners, ReferenceSearch }
})
export default class AddReference extends Vue {
  @Prop() readonly forView!: boolean;
  @Prop() readonly showCargoOwners!: boolean;
  @Prop() reference!: Bulk;
  @Prop() lists!: Lists;
  @Prop() addBulk!: Function;
  @Prop() customLabelBl!: Function;
  @Prop() clearForm!: Function;
  @Prop() findEntity!: Function;
  @Prop() form!: PreRegister;
  @Prop() module!: string;
  @Prop({ required: false, default: () => false }) isQuotation!: boolean;

  @Inject(TYPES.FINDBYREFERENCE_PACKAGING_REFERENCE)
  readonly findByReferencePackaging!: FindByReferencePackaging;

  //Helpers
  isLoading = false;

  //Listas
  packingUnitList: Packaging[] = [];

  get filteredReferences() {
    return this.lists.singleReferencesList?.filter(reference => !reference.noManageStock);
  }

  get disabledInputs() {
    return this.forView;
  }

  get allowBatch() {
    if (this.reference.reference) return this.reference.reference.allowBatch;

    return true;
  }

  getStatusValue(key: string) {
    if (key != 'cubed' && key != 'partial_pre_register' && key != 'pre_register' && key != 'shipment') return 1000;
    return preRegisterSteps[key];
  }

  add() {
    const number = this.reference.quantity;
    this.addBulk(this.reference, number, 'reference');
  }

  handleInventory(handleInventory: boolean) {
    handleInventory ? (this.reference.handleInventory = 'S') : (this.reference.handleInventory = 'N');
  }

  // Funcion invocada para setear los valores del embalaje en la linea seleccionada
  setPackingDimensions(packaing: Packaging, reference: Bulk) {
    reference.length = packaing.large;
    reference.width = packaing.width;
    reference.height = packaing.height;
  }

  //Funcion invocada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  //Funcion invocada cuando se selecciona una referencia
  async getPackingUnit(reference: References) {
    try {
      if (!reference) return;
      this.isLoading = true;
      const res = await this.findByReferencePackaging.execute(reference.code);
      this.packingUnitList = res ?? [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  onReferenceInput(value: References | null) {
    this.reference.packaging = null;
    if (!value) return;
    this.getPackingUnit(value);
    this.setCargoDetailCommodityFromReference(value);
  }

  setCargoDetailCommodityFromReference(reference: References) {
    this.reference.shippingDetails.commodity = reference?.commodity ?? null;
  }
}
